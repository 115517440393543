var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selected_brand.active_promotion)?_c('v-alert',{attrs:{"type":"info","elevation":"1","colored-border":"","border":"left"}},[_c('div',{staticClass:"d-flex justify-md-space-between align-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Your showcase campaign "),_c('b',[_vm._v(_vm._s(_vm.selected_brand.active_promotion.name))]),_vm._v(" is currently active. ")]),_c('v-btn',{attrs:{"color":"red darken-1","dark":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.update_active_promotion(null)}}},[_vm._v("Disable "+_vm._s(_vm.selected_brand.active_promotion.name))])],1)]):_vm._e(),_c('v-data-table',{staticClass:"br-16 elevation-1",attrs:{"headers":_vm.headers,"items":_vm._promotions,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center br-16 pa-4"},[_c('v-text-field',{staticClass:"br-8",staticStyle:{"max-width":"350px"},attrs:{"placeholder":"Filter campaigns","label":"Name","prepend-inner-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":"","to":{
            name: 'admin-brands-id-new-promotion',
            params: { brand_id: _vm.selected_brand.id },
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v("Add new showcase campaign")],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
          var item = ref.item;
return [(
          _vm.selected_brand.active_promotion &&
          _vm.selected_brand.active_promotion.id === item.id
        )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-close")]),_c('v-btn',{attrs:{"small":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.update_active_promotion(item.id)}}},[_vm._v("Activate")])],1)]}},{key:"item.shop_id",fn:function(ref){
        var item = ref.item;
return [(item.shop_id)?_c('router-link',{staticClass:"black--text",attrs:{"to":{ name: 'admin-shops-id', params: { id: item.shop_id } }}},[_vm._v(_vm._s(item.shop_id))]):_vm._e()]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
          name: 'admin-brands-id-new-promotion',
          params: { id: _vm.selected_brand.id },
          query: { id: item.id },
        }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.delete_promotion(item.id)}}},[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }