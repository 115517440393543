<template>
  <div>
    <v-alert
      v-if="selected_brand.active_promotion"
      type="info"
      elevation="1"
      colored-border
      border="left"
      ><div class="d-flex justify-md-space-between align-center">
        <p class="mb-0">
          Your showcase campaign
          <b>{{ selected_brand.active_promotion.name }}</b> is currently active.
        </p>
        <v-btn
          color="red darken-1"
          dark
          outlined
          rounded
          @click="update_active_promotion(null)"
          >Disable {{ selected_brand.active_promotion.name }}</v-btn
        >
      </div>
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="_promotions"
      :search="search"
      :items-per-page="10"
      class="br-16 elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center br-16 pa-4">
          <v-text-field
            v-model="search"
            class="br-8"
            placeholder="Filter campaigns"
            label="Name"
            style="max-width: 350px"
            prepend-inner-icon="search"
            hide-details
            outlined
            dense
          />
          <v-btn
            color="yellow"
            rounded
            :to="{
              name: 'admin-brands-id-new-promotion',
              params: { brand_id: selected_brand.id },
            }"
            class="mr-0"
          >
            <v-icon color="black" class="mr-2">mdi-plus</v-icon>Add new showcase
            campaign</v-btn
          >
        </div>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <v-icon
          v-if="
            selected_brand.active_promotion &&
            selected_brand.active_promotion.id === item.id
          "
          color="green"
          >mdi-check</v-icon
        >
        <div v-else>
          <v-icon color="red" class="mr-2">mdi-close</v-icon>
          <v-btn
            small
            outlined
            rounded
            @click="update_active_promotion(item.id)"
            >Activate</v-btn
          >
        </div>
      </template>
      <template v-slot:[`item.shop_id`]="{ item }">
        <router-link
          v-if="item.shop_id"
          class="black--text"
          :to="{ name: 'admin-shops-id', params: { id: item.shop_id } }"
          >{{ item.shop_id }}</router-link
        >
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          class="no-text-decoration"
          :to="{
            name: 'admin-brands-id-new-promotion',
            params: { id: selected_brand.id },
            query: { id: item.id },
          }"
        >
          <v-icon>mdi-pencil</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon @click="delete_promotion(item.id)">
          <v-icon color="red darken-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import {
  get_brand_promotions,
  update_brand_active_promotion,
  delete_promotion,
} from "@/requests";

export default {
  name: "BrandPromotions",
  data() {
    return {
      promotions: [],
      search: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "promotion_type" },
        { text: "Shop", value: "shop_id" },
        { text: "Active", value: "id" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  async mounted() {
    const { data: promotions } = await get_brand_promotions(
      this.selected_brand.id
    );
    this.promotions = promotions;
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    _promotions() {
      if (this.search) {
        return this.promotions.filter((p) => p.name.includes(this.search));
      }
      return this.promotions;
    },
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brand_by_id"]),
    async update_active_promotion(promotion_id) {
      await update_brand_active_promotion(this.selected_brand.id, {
        promotion_id: promotion_id,
      });
      await this.get_brand_by_id(this.selected_brand.id);
    },
    async delete_promotion(promotion_id) {
      await delete_promotion(promotion_id);
      const { data: promotions } = await get_brand_promotions(
        this.selected_brand.id
      );
      this.promotions = promotions;
      await this.get_brand_by_id(this.selected_brand.id);
    },
  },
};
</script>
